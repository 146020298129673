/* istanbul ignore file -- IntersectionObserver does not exist in jest, nor does jsdom actually do any layouting. */
import { RefObject, useEffect, useMemo, useRef, useState } from "react";
import { useIntersectionObserver } from "./useIntersectionObserver";
export const useStickyHeader = (tableContainerRef: RefObject<HTMLDivElement>, enableStickyHeader: boolean) => {
  const [width, setWidth] = useState<string>("");

  useEffect(() => {
    const currentTableContainer = tableContainerRef.current;

    if (!enableStickyHeader || !currentTableContainer) return;

    const updateWidth = () => {
      setWidth(currentTableContainer.getBoundingClientRect().width + "px");
    };

    const resizeObserver = new ResizeObserver(() => {
      updateWidth();
    });

    resizeObserver.observe(currentTableContainer);

    updateWidth();

    return () => {
      resizeObserver.unobserve(currentTableContainer);
    };
  }, [enableStickyHeader, tableContainerRef]);

  const theadElement = useRef<HTMLTableSectionElement | null>(null);

  const tableObserver = useIntersectionObserver(tableContainerRef.current);
  const tableHeadObserver = useIntersectionObserver(theadElement.current);

  useEffect(() => {
    const updateThead = () => {
      const thead = tableContainerRef.current?.getElementsByTagName("thead")[0];

      if (thead) {
        theadElement.current = thead;
      }
    };

    if (!theadElement.current) {
      updateThead();
    }
  }, [tableContainerRef]);

  const visible = useMemo(() => {
    if (!enableStickyHeader) return;

    const tableInView = tableObserver.inView;
    const theadInView = tableHeadObserver.inView;

    if (!theadInView && tableInView) {
      return true;
    }

    return false;
  }, [enableStickyHeader, tableHeadObserver.inView, tableObserver.inView]);

  return { visible, width };
};
