import { Big, InvoiceMethod, skipToken, useFeatureFlag, useGetProjectServiceQuery } from "@simplicate/api-client";
import { CurrencyFormat } from "@simplicate/number-format";
import { useTranslation } from "@simplicate/translations";
import { Alert, Icon, Tooltip, Page, Footer, Button, useDialogRef, Spinner } from "@simplicate/ui";
import { useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  ApplyChangedDefaultServiceValuesDialog,
  type DefaultServiceDialogForm,
} from "./ApplyChangedDefaultServiceValuesDialog";
import { CanRegisterCostsToggle } from "./fields/CanRegisterCostsToggle";
import { CanRegisterHoursToggle } from "./fields/CanRegisterHoursToggle";
import { DefaultServiceSelect } from "./fields/DefaultServiceSelect";
import { DescriptionTextInput } from "./fields/DescriptionTextInput";
import { ExplanationTextArea } from "./fields/ExplanationTextArea";
import { InvoiceableFromDatepicker } from "./fields/InvoiceableFromDatepicker";
import { InvoiceInInstallmentsCheckbox } from "./fields/InvoiceInInstallmentsCheckbox";
import { InvoiceMethodSelect } from "./fields/InvoiceMethodSelect";
import { InvoicePriceInput } from "./fields/InvoicePriceInput";
import { IsPlannableRadioButton } from "./fields/IsPlannableRadioButton";
import { RevenueGroupSelect } from "./fields/RevenueGroupSelect";
import { TimeframeEndDatePicker } from "./fields/TimeframeEndDatePicker";
import { TimeframeStartDatePicker } from "./fields/TimeframeStartDatePicker";
import { VATCodeSelect } from "./fields/VATCodeSelect";
import { FormikErrors } from "./FormikErrors";
import { CostTypeGrid } from "./grids/CostTypeGrid";
import { HourTypeGrid } from "./grids/HourTypeGrid";
import { HourTypesTotalsInfo } from "./grids/HourTypeGrid/HourTypeTotalsInfo/HourTypesTotalsInfo";
import { ProjectServiceHeader } from "./ProjectServiceHeader";
import styles from "./ProjectServicePage.module.scss";
import { ProjectServicePageTemplate } from "./ProjectServicePage.template";
import { ProjectServicePageSkeleton } from "./ProjectServicePageSkeleton";
import { ProjectServiceTotalInfo } from "./ProjectServiceTotalInfo";
import { transformProjectServiceToForm } from "./transformProjectServiceToForm";
import { useProjectServiceForm } from "./useProjectServiceForm";
import { useServiceTotal } from "./useServiceTotal";

type ProjectServicePageParams = {
  id: string;
  serviceId?: string;
};

const useProjectService = (serviceId?: string) => {
  const { data, isLoading } = useGetProjectServiceQuery(serviceId ?? skipToken);

  const service = data ? transformProjectServiceToForm(data) : undefined;

  return { service, isLoading };
};

// TODO determine some good ways to logically split this component
// eslint-disable-next-line complexity, sonarjs/cognitive-complexity -- This component is large,
export const ProjectServicePage = () => {
  const { t } = useTranslation("project_services");
  const navigate = useNavigate();

  const params = useParams<ProjectServicePageParams>();

  const { id: projectId, serviceId } = params;

  const dialogRef = useDialogRef<DefaultServiceDialogForm>();
  const formRef = useRef<HTMLFormElement>(null);

  const { service: initialValues, isLoading: serviceLoading } = useProjectService(serviceId);

  const {
    values,
    errors,
    touched,
    setDefaultService,
    setInvoiceMethod,
    setDescription,
    setExplanation,
    setInvoiceableFrom,
    setInvoiceInInstallments,
    setStartDate,
    setEndDate,
    setRevenueGroup,
    setVatCode,
    setCanRegisterHours,
    setCanRegisterCosts,
    setAmountForHourType,
    setTariffForHourType,
    toggleIsInvoiceableForHourType,
    toggleIsInvoiceableForCostType,
    setIsPlannable,
    setHourTypesTotal,
    removeNewHourTypeEntry,
    addHourType,
    removeCostType,
    removeHourTypes,
    addCostType,
    setLabelForCostType,
    setQuantityForCostType,
    setPurchasePriceForCostType,
    setMarginForCostType,
    setSellingPriceForCostType,
    setInvoicePrice,
    handleSubmit: handleFormikSubmit,
    isSubmitting,
  } = useProjectServiceForm({
    initialValues,
    projectId,
    dialogRef,
    serviceId,
    afterSubmitTarget: `/projects/${projectId}/services`,
  });

  const canHaveInvoiceableFromValue = !values.invoiceInInstallments;
  const isDefaultServiceUndefined = values.defaultService === undefined;
  const isHourTypeGridDisabled = !values.canRegisterHours || isDefaultServiceUndefined;
  const isCostTypeGridDisabled = !values.canRegisterCosts || isDefaultServiceUndefined;
  const isEditingService = serviceId !== undefined;
  const isCanRegisterHoursLocked =
    (values.hourTypes?.some(({ hasRegistrations }) => hasRegistrations) ?? false) && (values.canRegisterHours ?? false);
  const isCanRegisterCostsLocked =
    (values.costTypes?.some(({ hasRegistrations }) => hasRegistrations) ?? false) && (values.canRegisterCosts ?? false);

  const hasResourcePlanner = useFeatureFlag("resource-planner").enabled;
  const isEditing = serviceId !== undefined;

  const serviceTotal = useServiceTotal({
    hourTypesSpecifiedTotalAmount: values.hourTypesSpecifiedTotal?.amount ?? Big(0),
    costTypes: values.costTypes ?? [],
  });

  const scrollToErrors = () => {
    const selector = Object.keys(errors)
      .map((name) => `[name="${name}"]`)
      .join(", ");

    formRef?.current?.querySelector(selector)?.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isSubmitting) handleFormikSubmit?.(e);
    if (Object.keys(errors).length > 0) scrollToErrors();
  };

  if (serviceLoading) {
    return <ProjectServicePageSkeleton testId="project-service-page-skeleton" />;
  }

  return (
    <Page
      footer={
        <Footer>
          <Button type="submit" form="projectservice-form" testId="submit-button">
            {t("buttons.save", { ns: "general" })}
          </Button>
          <Button onClick={() => navigate(-1)} variant="subtle" testId="cancel-button">
            {t("buttons.cancel", { ns: "general" })}
          </Button>
          {isSubmitting && (
            <div className={styles.isSubmittingContainer}>
              <Spinner testId="project-service-page-is-submitting-spinner" />
              <span className={styles.normalText}>{t("service_is_being_submitted")}</span>
            </div>
          )}
        </Footer>
      }
    >
      <ApplyChangedDefaultServiceValuesDialog ref={dialogRef} />

      <form onSubmit={handleSubmit} ref={formRef} id="projectservice-form" data-testid="project-service-page">
        <ProjectServicePageTemplate>
          <ProjectServicePageTemplate.Alerts>
            <Alert type="info">
              <span>{t("pilot_notice")}</span>
            </Alert>
          </ProjectServicePageTemplate.Alerts>

          <ProjectServicePageTemplate.Title>
            <ProjectServiceHeader
              title={isEditing ? values.description ?? "" : t("create_project_service_page_title")}
              invoiceMethod={isEditing ? values.invoiceMethod : undefined}
              price={isEditing ? serviceTotal : undefined}
            />
          </ProjectServicePageTemplate.Title>

          <ProjectServicePageTemplate.Section>
            <ProjectServicePageTemplate.PropertiesBlock>
              <ProjectServicePageTemplate.Title>
                <h4 className={styles.subTitle}>{t("basic_properties")}</h4>
              </ProjectServicePageTemplate.Title>

              <ProjectServicePageTemplate.PropertiesBlockColumn>
                <DefaultServiceSelect
                  value={values.defaultService}
                  error={errors.defaultService}
                  touched={touched.defaultService}
                  onSelect={setDefaultService}
                  disabled={values.existsOnInvoice}
                  tooltip={values.existsOnInvoice ? t("default_service_locked_invoices_tooltip") : undefined}
                />

                <InvoiceMethodSelect
                  value={values.invoiceMethod}
                  error={errors.invoiceMethod}
                  touched={touched.invoiceMethod}
                  onSelect={setInvoiceMethod}
                  disabled={isEditingService || isDefaultServiceUndefined}
                  disabledTooltip={isEditingService ? t("invoice_method_cannot_be_changed") : undefined}
                />

                <div className={styles.timeframeLayout}>
                  <div className={styles.timeframeSection}>
                    <h4 className={styles.subTitle}>{t("timeframe")}</h4>

                    <Tooltip message={t("optional")} position="right">
                      <Icon icon="infoCircle" className={styles.infoIcon} />
                    </Tooltip>
                  </div>

                  <div className={styles.timeframeFields}>
                    <div className={styles.timeframeFieldContainer}>
                      <TimeframeStartDatePicker
                        value={values.timeframe.startDate}
                        onChange={setStartDate}
                        error={errors.timeframe?.startDate}
                        touched={touched.timeframe?.startDate}
                        disabled={isDefaultServiceUndefined}
                      />
                    </div>

                    <div className={styles.timeframeFieldContainer}>
                      <TimeframeEndDatePicker
                        value={values.timeframe.endDate}
                        minValue={values.timeframe.startDate}
                        onChange={setEndDate}
                        error={errors.timeframe?.endDate}
                        touched={touched.timeframe?.endDate}
                        disabled={isDefaultServiceUndefined}
                      />
                    </div>
                  </div>
                </div>
              </ProjectServicePageTemplate.PropertiesBlockColumn>

              <ProjectServicePageTemplate.PropertiesBlockColumn>
                <DescriptionTextInput
                  value={values.description}
                  error={errors.description}
                  touched={touched.description}
                  onChange={setDescription}
                  disabled={isDefaultServiceUndefined}
                />

                <ExplanationTextArea
                  value={values.explanation}
                  error={errors.explanation}
                  touched={touched.explanation}
                  onChange={setExplanation}
                  disabled={isDefaultServiceUndefined}
                />
              </ProjectServicePageTemplate.PropertiesBlockColumn>
            </ProjectServicePageTemplate.PropertiesBlock>

            <ProjectServicePageTemplate.OptionsBlock>
              <ProjectServicePageTemplate.Title>
                <h4 className={styles.subTitle}>{t("advanced_properties")}</h4>
              </ProjectServicePageTemplate.Title>

              <ProjectServicePageTemplate.OptionsBlockColumn>
                <div className={styles.column}>
                  <RevenueGroupSelect
                    value={values.revenueGroup}
                    error={errors.revenueGroup}
                    touched={touched.revenueGroup}
                    onSelect={setRevenueGroup}
                    disabled={isDefaultServiceUndefined || values.existsOnInvoice}
                    tooltip={values.existsOnInvoice ? t("revenue_group_locked_invoices_tooltip") : undefined}
                  />

                  <VATCodeSelect
                    value={values.vatCode}
                    error={errors.vatCode}
                    touched={touched.vatCode}
                    onSelect={setVatCode}
                    disabled={isDefaultServiceUndefined || values.existsOnInvoice}
                    tooltip={values.existsOnInvoice ? t("vat_code_locked_invoices_tooltip") : undefined}
                  />
                </div>
              </ProjectServicePageTemplate.OptionsBlockColumn>
            </ProjectServicePageTemplate.OptionsBlock>
          </ProjectServicePageTemplate.Section>

          <ProjectServicePageTemplate.Section>
            <ProjectServicePageTemplate.Content>
              <div className={styles.gridTitleContainer}>
                <CanRegisterHoursToggle
                  label={<h3 className={styles.sectionTitle}>{t("hour_registration")}</h3>}
                  value={values.canRegisterHours ?? false}
                  onChange={setCanRegisterHours}
                  disabled={isDefaultServiceUndefined || isCanRegisterHoursLocked}
                  tooltip={isCanRegisterHoursLocked ? t("cannot_disable_can_register_hours") : undefined}
                />

                <FormikErrors touched={touched.hourTypes} error={errors.hourTypes} />

                <FormikErrors touched={touched.canRegisterHours} error={errors.canRegisterHours} />
              </div>

              {hasResourcePlanner && (
                <IsPlannableRadioButton
                  value={values.isPlannable ?? false}
                  onChange={setIsPlannable}
                  disabled={isHourTypeGridDisabled || values.hasAssignments}
                  tooltip={values.hasAssignments ? t("is_plannable_locked_tooltip") : undefined}
                />
              )}

              <div className={styles.hourTypeGridContainer}>
                <HourTypeGrid
                  value={values.hourTypes}
                  onAmountChange={setAmountForHourType}
                  onTariffChange={setTariffForHourType}
                  onIsInvoiceableToggled={toggleIsInvoiceableForHourType}
                  onAddNewHourTypeEntry={addHourType}
                  onCancelNewHourTypeEntry={removeNewHourTypeEntry}
                  onRemoveHourTypeEntries={removeHourTypes}
                  onChangeSpecifiedTotal={setHourTypesTotal}
                  specifiedTotal={values.hourTypesSpecifiedTotal}
                  showIsInvoiceableColumn={values.invoiceMethod === InvoiceMethod.time_and_expenses}
                  disabled={isHourTypeGridDisabled}
                />
                <HourTypesTotalsInfo
                  hourTypes={values.hourTypes}
                  specifiedTotal={values.hourTypesSpecifiedTotal}
                  setSpecifiedTotal={setHourTypesTotal}
                />
              </div>
            </ProjectServicePageTemplate.Content>

            <ProjectServicePageTemplate.Content>
              <div className={styles.gridTitleContainer}>
                <CanRegisterCostsToggle
                  label={<h3 className={styles.sectionTitle}>{t("expense_registration")}</h3>}
                  value={values.canRegisterCosts ?? false}
                  onChange={setCanRegisterCosts}
                  disabled={isDefaultServiceUndefined || isCanRegisterCostsLocked}
                  tooltip={isCanRegisterCostsLocked ? t("cannot_disable_can_register_costs") : undefined}
                />

                <FormikErrors touched={touched.canRegisterCosts} error={errors.canRegisterCosts} />
              </div>

              <CostTypeGrid
                value={values.costTypes}
                onAddCostType={addCostType}
                onRemoveCostType={removeCostType}
                onLabelChange={setLabelForCostType}
                onQuantityChange={setQuantityForCostType}
                onMarginChange={setMarginForCostType}
                onPurchasePriceChange={setPurchasePriceForCostType}
                onSellingPriceChange={setSellingPriceForCostType}
                disabled={isCostTypeGridDisabled}
                showIsInvoiceableColumn={values.invoiceMethod === InvoiceMethod.time_and_expenses}
                onIsInvoiceableToggled={toggleIsInvoiceableForCostType}
              />
            </ProjectServicePageTemplate.Content>

            {values.invoiceMethod === InvoiceMethod.fixed_price && (
              <ProjectServicePageTemplate.Content>
                <div className={styles.fixedPriceInvoicingLayout}>
                  <h4 className={styles.subTitle}>{t("invoicing")}</h4>

                  <div className={styles.fixedPriceInvoicingFields}>
                    <InvoiceableFromDatepicker
                      value={canHaveInvoiceableFromValue ? values.invoiceableFrom : undefined}
                      onChange={setInvoiceableFrom}
                      error={errors.invoiceableFrom}
                      touched={touched.invoiceableFrom}
                      disabled={values.invoiceInInstallments || values.existsOnInvoice}
                      tooltip={values.existsOnInvoice ? t("invoiceable_from_locked_invoices_tooltip") : undefined}
                    />

                    <InvoiceInInstallmentsCheckbox
                      value={values.invoiceInInstallments}
                      onChange={setInvoiceInInstallments}
                      disabled={values.hasInstallmentPlan || values.existsOnInvoice}
                      tooltip={values.hasInstallmentPlan ? t("invoice_in_installments_locked") : undefined}
                    />
                  </div>
                </div>
              </ProjectServicePageTemplate.Content>
            )}
          </ProjectServicePageTemplate.Section>
          <ProjectServicePageTemplate.Section>
            <ProjectServicePageTemplate.TotalBlock>
              <div className={styles.totalBlock}>
                {values.invoiceMethod === InvoiceMethod.fixed_price && (
                  <InvoicePriceInput
                    value={values.invoicePrice}
                    onChange={setInvoicePrice}
                    disabled={values.existsOnInvoice}
                    tooltip={values.existsOnInvoice ? t("invoice_price_locked_invoices_tooltip") : undefined}
                  />
                )}
                <div className={styles.serviceTotalContainer}>
                  <span className={styles.normalText}>{t("service_total")}</span>
                  <CurrencyFormat className={styles.normalText} displayType="text" value={serviceTotal.toString()} />
                </div>
              </div>
              {values.invoiceMethod === InvoiceMethod.fixed_price && !values.existsOnInvoice && (
                <ProjectServiceTotalInfo
                  calculatedTotal={serviceTotal}
                  invoicePrice={values.invoicePrice}
                  setInvoicePrice={setInvoicePrice}
                />
              )}
            </ProjectServicePageTemplate.TotalBlock>
          </ProjectServicePageTemplate.Section>
        </ProjectServicePageTemplate>
      </form>
    </Page>
  );
};
