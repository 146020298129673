import { useGetDefaultServicesQuery } from "@simplicate/api-client";
import { useTranslation } from "@simplicate/translations";
import { Select, Tooltip } from "@simplicate/ui";
import { memo } from "react";

type DefaultServiceSelectProps = {
  value: string | undefined;
  touched?: boolean;
  error: string | undefined;
  onSelect: (id: string) => void;
  disabled?: boolean;
  tooltip?: string;
};

export const DefaultServiceSelect = memo(function DefaultServiceSelect({
  value,
  onSelect,
  error,
  touched = false,
  disabled = false,
  tooltip,
}: DefaultServiceSelectProps) {
  const { t } = useTranslation("project_services");

  const { data: options } = useGetDefaultServicesQuery();

  const compatibleOptions = options?.map((option) => ({
    label: option.description,
    value: option.identifier,
  }));

  const select = (
    <Select
      testId="default_service_select"
      name="defaultService"
      label={t("default_service")}
      placeholder={t("default_service_placeholder")}
      emptyFilterMessage={t("no_default_services_found")}
      emptyMessage={t("no_default_services")}
      value={value}
      options={compatibleOptions}
      onSelect={onSelect}
      invalid={touched && error !== undefined}
      disabled={disabled}
    />
  );

  return tooltip ? (
    <Tooltip message={tooltip} position="right">
      {select}
    </Tooltip>
  ) : (
    select
  );
});
