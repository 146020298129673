import { toggleMainMenuExpanded, useAppDispatch, useAppSelector } from "@simplicate/state";
import { TopBar } from "@simplicate/top-bar";
import { ToastContainer, usePortalContext, MODAL_ANCHOR } from "@simplicate/ui";
import classNames from "classnames";
import { useEffect, useRef } from "react";
import { Outlet } from "react-router-dom";
import { MainMenu } from "../MainMenu";
import { PermissionBoundary } from "../PermissionBoundary";
import styles from "./NavigationWrapper.module.scss";
import { GeneralSubHeader } from "./SubHeaders";
import { useHistoryFix } from "./useHistoryFix";
import { useRouteConfig } from "./useRouteConfig";

export const NavigationWrapper = () => {
  const { expanded } = useAppSelector(/* istanbul ignore next */ ({ state }) => state.mainMenu);
  const { showOverlay } = useAppSelector(/* istanbul ignore next */ ({ state }) => state.header);
  const dispatch = useAppDispatch();

  const {
    theme: themeClass,
    module,
    backNavigationPath,
    subHeaderComponent: SubHeaderComponent,
    tabsComponent: TabsComponent,
  } = useRouteConfig();

  useHistoryFix();

  const hasSubHeaderComponent = !!SubHeaderComponent;
  const hasTabs = !!TabsComponent;
  const onToggleExpanded = () => dispatch(toggleMainMenuExpanded());

  const { setTargetRef } = usePortalContext();
  const topBarRef = useRef(null);
  const overlayRef = useRef(null);

  useEffect(() => {
    if (topBarRef.current) {
      return setTargetRef("page-top-bar", topBarRef);
    }
  }, [setTargetRef]);

  useEffect(() => {
    if (overlayRef.current) {
      return setTargetRef(MODAL_ANCHOR, overlayRef);
    }
  }, [setTargetRef]);

  return (
    <>
      <div id="nav-wrapper" className={classNames(themeClass, styles.layout, expanded && styles.expanded)}>
        <div className={styles.mainMenu}>
          <MainMenu expanded={expanded} onToggleExpanded={onToggleExpanded} />
        </div>
        <div
          className={classNames(styles.topBar, showOverlay && styles.overlay)}
          data-testid="top-bar-nav"
          ref={topBarRef}
        >
          <TopBar backButtonTarget={backNavigationPath} module={module}>
            {hasSubHeaderComponent ? <SubHeaderComponent /> : <GeneralSubHeader />}
          </TopBar>
          {hasTabs && <TabsComponent />}
        </div>
        <main id="main" className={classNames(styles.outlet, hasTabs && styles.withTabs, expanded && styles.expanded)}>
          <PermissionBoundary>
            <Outlet />
          </PermissionBoundary>
        </main>
      </div>
      <div id="global-overlay" ref={overlayRef} className={styles.overlayContainer} />
      <ToastContainer />
    </>
  );
};
