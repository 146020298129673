import { skipToken } from "@reduxjs/toolkit/query/react";
import { useTranslation } from "@simplicate/translations";
import { Button, Input, Select, Icon } from "@simplicate/ui";
import { lightColorIconSuccess } from "@simplicate-software/design-tokens";
import { useEffect, useRef } from "react";
import { useGetCostTypesQuery, useGetCostTypeQuery } from "../../../../data/endpoints";
import { transformCostTypeToCostTypeInForm } from "../../../../transformCostTypes";
import { type CostTypeInForm } from "../../../../types";
import { useCostTypeMutationForm } from "../../CostTypeMutationForm";
import styles from "./AddCostTypeRow.module.scss";

type CostTypeGridFooterProps = {
  onConfirmNewEntry: (entry: CostTypeInForm) => void;
  onCancelNewEntry: () => void;
  disabled?: boolean;
};

export const AddCostTypeRow = ({ onConfirmNewEntry, onCancelNewEntry, disabled = false }: CostTypeGridFooterProps) => {
  const { t } = useTranslation("project_services");
  const labelInputRef = useRef<HTMLInputElement>(null);

  /* istanbul ignore next - submitForm function is mocked in tests */
  const confirmEntry = (label: string | undefined) =>
    selectedCostType &&
    onConfirmNewEntry(transformCostTypeToCostTypeInForm(selectedCostType, label ?? selectedCostType.name));

  const { values, touched, errors, setCostTypeIdentifier, setLabel, isValid, submitForm } = useCostTypeMutationForm({
    onSubmit: confirmEntry,
  });

  const { data: options } = useGetCostTypesQuery();
  const { data: selectedCostType } = useGetCostTypeQuery(
    values.costTypeIdentifier ? values.costTypeIdentifier : skipToken,
  );

  useEffect(() => {
    if (selectedCostType && (!touched.label || values.label === "")) {
      void setLabel(selectedCostType.name, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- This effect should NOT run when label value changes, only when costType changes
  }, [selectedCostType, setLabel, touched.label]);

  const compatibleOptions = options?.map((option) => ({
    label: option.name,
    value: option.identifier,
  }));

  return (
    <div className={styles.container} data-testid="add-new-cost-type-row">
      <Select
        testId="cost-type-select"
        value={values.costTypeIdentifier}
        invalid={touched.costTypeIdentifier && errors.costTypeIdentifier !== undefined}
        options={compatibleOptions}
        placeholder={t("cost_type_placeholder")}
        onSelect={(value) => {
          void setCostTypeIdentifier(value);

          setTimeout(() => {
            labelInputRef.current?.focus();
            labelInputRef.current?.select();
          }, 100);
        }}
        disabled={disabled}
      />
      <Input
        ref={labelInputRef}
        testId="cost-type-label"
        type="text"
        name="cost-type-label"
        value={values.label ?? ""}
        hasError={touched.label && errors.label !== undefined}
        onChange={(event) => void setLabel(event.target.value)}
        placeholder={t("cost_type_label_placeholder")}
        size="small"
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.stopPropagation();
            e.preventDefault();
            void submitForm();

            return;
          }

          if (e.key === "Escape") {
            e.stopPropagation();
            e.preventDefault();
            onCancelNewEntry();
          }
        }}
        disabled={disabled}
      />
      <Button
        testId="confirm-new-cost-type"
        variant="secondary"
        onClick={() => void submitForm()}
        disabled={disabled || !isValid || (!touched.label && !touched.costTypeIdentifier)}
        type="button"
      >
        <Icon icon="check" color={lightColorIconSuccess} />
      </Button>
      <Button
        testId="cancel-new-cost-type"
        variant="secondary"
        onClick={onCancelNewEntry}
        type="button"
        disabled={disabled}
      >
        <Icon icon="times" />
      </Button>
    </div>
  );
};
