import { useTranslation } from "@simplicate/translations";
import { ActionDropdown, Icon } from "@simplicate/ui";
import { lightColorIconError } from "@simplicate-software/design-tokens";

type Action = {
  onClick?: (value: string) => void;
  disabled?: boolean;
  tooltip?: string;
};

type CostTypeActionDropdownProps = {
  identifier: string;
  isInvoiceable?: boolean;
  edit: Action;
  toggleIsInvoiceable: Action;
  remove: Action;
  disabled?: boolean;
};

export const CostTypeActionDropdown = ({
  identifier,
  edit,
  remove,
  toggleIsInvoiceable,
  disabled = false,
  isInvoiceable = false,
}: CostTypeActionDropdownProps) => {
  const { t } = useTranslation("project_services");

  return (
    <ActionDropdown
      testId="cost-type-actions"
      button={
        <ActionDropdown.Button variant="subtle" testId="cost-type-actions-button" disabled={disabled}>
          <Icon icon="ellipsisVertical" />
        </ActionDropdown.Button>
      }
    >
      <ActionDropdown.Action disabled={edit.disabled} tooltip={edit.tooltip} onClick={() => edit.onClick?.(identifier)}>
        <Icon icon="pen" />
        {t("cost_type_edit_label")}
      </ActionDropdown.Action>

      {toggleIsInvoiceable.onClick && (
        <ActionDropdown.Action
          disabled={toggleIsInvoiceable.disabled}
          tooltip={toggleIsInvoiceable.tooltip}
          onClick={() => toggleIsInvoiceable.onClick?.(identifier)}
        >
          <Icon icon="fileInvoice" />
          {isInvoiceable ? t("set_is_not_invoiceable") : t("set_is_invoiceable")}
        </ActionDropdown.Action>
      )}

      <ActionDropdown.Action
        disabled={remove.disabled}
        tooltip={remove.tooltip}
        onClick={() => remove.onClick?.(identifier)}
      >
        <Icon icon="trash" color={lightColorIconError} />
        {t("remove_from_grid")}
      </ActionDropdown.Action>
    </ActionDropdown>
  );
};
