import { useTranslation } from "@simplicate/translations";
import { ActionDropdown, Icon } from "@simplicate/ui";
import { lightColorIconError } from "@simplicate-software/design-tokens";
import { memo } from "react";

type Action = {
  onClick?: (value: string) => void;
  disabled?: boolean;
  tooltip?: string;
};

type HourTypeActionDropdownProps = {
  identifier: string;
  isInvoiceable?: boolean;
  moveToTop: Action;
  moveToBottom: Action;
  toggleIsInvoiceable: Action;
  remove: Action;
  disabled?: boolean;
};

export const HourTypeActionDropdown = memo(function HourTypeActionDropdown({
  identifier,
  toggleIsInvoiceable,
  remove,
  isInvoiceable = false,
  disabled = false,
}: HourTypeActionDropdownProps) {
  const { t } = useTranslation("project_services");

  return (
    <ActionDropdown
      testId="hour-type-actions"
      button={
        <ActionDropdown.Button variant="subtle" disabled={disabled} testId="hour-type-actions-button">
          <Icon icon="ellipsisVertical" />
        </ActionDropdown.Button>
      }
    >
      {toggleIsInvoiceable.onClick && (
        <ActionDropdown.Action
          disabled={toggleIsInvoiceable.disabled}
          tooltip={toggleIsInvoiceable.tooltip}
          onClick={() => toggleIsInvoiceable.onClick?.(identifier)}
        >
          <Icon icon="fileInvoice" />
          {isInvoiceable ? t("set_is_not_invoiceable") : t("set_is_invoiceable")}
        </ActionDropdown.Action>
      )}
      <ActionDropdown.Action
        tooltip={remove.tooltip ? remove.tooltip : undefined}
        disabled={remove.disabled}
        onClick={() => remove.onClick?.(identifier)}
      >
        <Icon icon="trash" color={lightColorIconError} />
        {t("remove_from_grid")}
      </ActionDropdown.Action>
    </ActionDropdown>
  );
});
