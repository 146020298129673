/* istanbul ignore file -- IntersectionObserver does not exist in jest, nor does jsdom actually do any layouting. */
import { useState, useLayoutEffect } from "react";

interface IntersectionObserverOptions {
  threshold?: number;
}

interface IntersectionObserverResult {
  inView: boolean;
}

export const useIntersectionObserver = (
  element: HTMLElement | null,
  options: IntersectionObserverOptions = {},
): IntersectionObserverResult => {
  const { threshold = 0 } = options;
  const [inView, setInView] = useState(false);

  useLayoutEffect(() => {
    if (!element) return;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          setInView(entry.isIntersecting);
        });
      },
      { threshold },
    );

    observer.observe(element);

    return () => {
      observer.disconnect();
    };
  }, [element, threshold]);

  return { inView };
};
